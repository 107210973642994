import React from 'react'
import AdminLayout from '../AdminLayout/AdminLayout';
import './Specialites.css';

const Specialites = () => {
    return (
        <>
            <AdminLayout >
               
            </AdminLayout>
        </>
    )
}
export default Specialites;