
import React, { useEffect } from "react";
import userImg from "../../images/avatar.jpg";
import { Button } from "antd";
import { useGetAllCommentQuery } from "../../redux/api/commentApi";
import { Empty, message } from "antd";
import AddCommnet from "./AddCommnet";
import Comment from "./Comment";

const BlogComment = (props) => {
  const user=props?.data;
  const data=props.data?.comment;

  return (
    <div className="mx-3" style={{ marginTop: "7rem" }}>
      <h5 className="mb-5" style={{ fontWeight: "900" }}>
        COMMENTS
      </h5>
      <AddCommnet data={data} user={user}/>
     {data?.length>0?data.map((data,index)=> 
    (<Comment data={data}key={index} user={user}/>)
  ):<Empty/>}

     
    </div>
  );
};

export default BlogComment;
