import React, { useEffect, useState } from 'react'
import img from '../../../../images/avatar.jpg';
import { FaEye, FaCheck, FaTimes, FaBriefcaseMedical } from "react-icons/fa";
import { useGetDoctorAppointmentsQuery, useUpdateAppointmentMutation } from '../../../../redux/api/appointmentApi';
import moment from 'moment';
import { Button, Tag, message,Modal,Input } from 'antd';
import CustomTable from '../../../UI/component/CustomTable';
import { Tabs } from 'antd';
import { Link } from 'react-router-dom';

const { TextArea } = Input;

const DashboardPage = () => {
    const [todayAppointments, setTodayAppointments] = useState([]);
    const [upcomingAppointments, setUpcomingAppointments] = useState([]);

    const sortBy = 'all';
    const { data, refetch, isLoading } = useGetDoctorAppointmentsQuery({ sortBy });
    const [updateAppointment, { isError, isSuccess, error }] = useUpdateAppointmentMutation();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const [currentAppointmentId, setCurrentAppointmentId] = useState(null);

    useEffect(() => {
        if (data) {
            const today = moment().startOf('day');
            const todayData = data.filter(app => moment(app.scheduleDate).isSame(today, 'day'));
            const upcomingData = data.filter(app => moment(app.scheduleDate).isAfter(today));
            
            setTodayAppointments(todayData);
            setUpcomingAppointments(upcomingData);
        }
    }, [data]);


    const handleCancelClick = (id) => {
        setCurrentAppointmentId(id);
        setIsModalVisible(true);
    }

    const handleOk = () => {
        updatedApppointmentStatus(currentAppointmentId, 'cancel', cancelReason);
        setIsModalVisible(false);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        setCancelReason('');
    }


    const updatedApppointmentStatus = (id, type) => {
        const changeObj = {
            status: type
        }
        const reason= cancelReason;
        if (id) {
            updateAppointment({ id, data: changeObj, reason:reason })
        }
    }

    useEffect(() => {
        if (isSuccess) {
            message.success("Succcessfully Appointment Updated")
        }
        if (isError) {
            message.error(error?.data?.message);
        }
    }, [isSuccess, isError, error])

    const upcomingColumns = [
        {
            title: 'Patient Name',
            key: '1',
            width: 150,
            render: function (data) {
                const fullName = `${data?.firstName ?? ''} ${data?.lastName ?? ''}`;
                const patientName = fullName.trim() || "Un Patient";
                const imgdata = data?.patient?.img ? data?.patient?.img : img
                return <>
                    <div className="table-avatar">
                        <a className="avatar avatar-sm mr-2 d-flex gap-2">
                            <img className="avatar-img rounded-circle" src={imgdata} alt="" />
                            <div>
                                <p className='p-0 m-0 text-nowrap'>
                                    {patientName}
                                </p>
                                <p className='p-0 m-0'>{data?.patient?.designation}</p>
                            </div>
                        </a>
                    </div>
                </>
            }
        },
        {
            title: 'App Date',
            key: '2',
            width: 120,
            render: function (data) {
                return (
                    <div>{moment(data?.scheduleDate).format("LL")} <span className="d-block text-info">{data?.scheduleTime}</span></div>
                )
            }
        },
        {
            title: 'Status',
            key: '4',
            width: 80,
            render: function (data) {
                return (
                    <Tag color="#87d068" className='text-uppercase'>{data?.status}</Tag>
                )
            }
        },
        {
            title: 'Action',
            key: '5',
            width: 100,
            render: function (data) {
                return (
                    <div className='d-flex gap-2 flex-wrap'>
                        {
                            data.prescriptionStatus === 'notIssued'
                                ?
                                <Link to={`/dashboard/appointment/treatment/${data?.id}`}>
                                    <Button type="primary" icon={<FaBriefcaseMedical />} size="small">Treatment</Button>
                                </Link>

                                :
                                <Link to={`/dashboard/prescription/${data?.prescription[0]?.id}`}>
                                    <Button type="primary" shape="circle" icon={<FaEye />} size="small" />
                                </Link>
                        }
                        {
                            data?.status === 'pending' &&
                            <>
                                <Button type="primary" icon={<FaCheck />} size="small" onClick={() => updatedApppointmentStatus(data.id, 'accept')}>Accept</Button>
                                <Button type='primary' icon={<FaTimes />} size='small' danger onClick={() => handleCancelClick(data.id)}>Cancel</Button>
                            </>
                        }
                    </div>
                )
            }
        },
    ];

    const items = [
        {
            key: '1',
            label: 'today',
            children: <CustomTable
                loading={isLoading}
                columns={upcomingColumns}
                dataSource={todayAppointments}

                showPagination={true}
                pageSize={10}
                showSizeChanger={true}
            />,
        },
        {
            key: '2',
            label: 'upcoming',
            children: <CustomTable
                loading={isLoading}
                columns={upcomingColumns}

               dataSource={upcomingAppointments}
                showPagination={true}
                pageSize={10}
                showSizeChanger={true}
            />,
        },
    ];

    return (

        <>
        <Tabs defaultActiveKey="1" items={items}  />
       <Modal
                title="Cancel Appointment"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>Please provide a reason for canceling the appointment:</p>
                <TextArea rows={4} value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} />
            </Modal>
            </>    

    )
}

export default DashboardPage;