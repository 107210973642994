import React, { useEffect } from "react";
import { useState } from "react";
import useAuthCheck from "../../redux/hooks/useAuthCheck";
import { useCreateCommentMutation } from "../../redux/api/commentApi";
import { useForm } from "react-hook-form";
import { Button, message } from "antd";
import "./index.css";
import { useNavigate } from "react-router-dom";

function AddCommnet({ user, isReply, setisReply, Blog, id }) {
  const Navigate = useNavigate();
  const { data: userData, authChecked } = useAuthCheck();
  const [focus, setfocus] = useState(false);
  const [check, setcheck] = useState(false);
  const { register, handleSubmit } = useForm({});
  const [createComment] = useCreateCommentMutation();
  const parentId = id;
  const onSubmit = async (data) => {
    if (userData && data.message) {
      const formData = new FormData();
      const jsonData = JSON.stringify({
        ...data,
        authorId: userData?.designation ? userData?.id : null,
        pateintId: !userData?.designation ? userData?.id : null,
        blogId: isReply ? Blog?.id : user?.id,
        parentId: parentId,
        role: userData.designation ? "doctor" : "patient",
      });

      formData.append("data", jsonData);

      const formDataJsonString = formData.get("data");

      const jsonObject = JSON.parse(formDataJsonString);

      await createComment(jsonObject);
    }
  };

  function clickhandler() {
    if (!authChecked) {
      Navigate("/login");
    } else {
      window.location.reload(false);
    }
  }

  return (
    <div className={isReply && `mt-3`}>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <img
              src={
                userData?.img ||
                "https://img.freepik.com/free-vector/man-red-shirt-with-white-collar_90220-2873.jpg?w=826&t=st=1722017224~exp=1722017824~hmac=8db4285629a69fed11d62d36051f8de2ae21bc0dfe2d6a33d49547e5b3fa2d2f"
              }
              width={isReply ? 30 : 25}
              className=" rounded-circle "
              alt="user imge"
            />
            <input
              placeholder="  Add commnet"
              className={`border-0 border-bottom border-primary  mx-3 w-75 ${
                isReply && "AddCommnet"
              }`}
              onClick={() => {
                setfocus(true);
              }}
              type="text"
              {...register("message")}
              style={{ outline: "none" }}
              onChange={() => {
                setcheck(true);
              }}
            ></input>
          </div>
          {(focus || isReply) && (
            <div className={`d-flex gap-3 justify-content-end w-75 mt-2 mx-5`}>
              <span>
                <input
                  type="button"
                  value="Cancel"
                  onClick={() => {
                    setfocus(!focus);
                    isReply && setisReply(false);
                  }}
                  className={`${
                    !isReply
                      ? "px-3 text-sm-center btn btn-outline-secondary  rounded-5 btn-sm"
                      : "button-B"
                  }`}
                />
              </span>
              <span>
                <Button
                  htmlType="submit"
                  className={`${
                    !isReply
                      ? "px-3 text-sm-center btn btn-outline-primary text-pri rounded-5 btn-sm"
                      : "button-B"
                  }`}
                  onClick={() => {
                    clickhandler();
                  }}
                  disabled={!check}
                >
                  Add Comment
                </Button>
              </span>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default AddCommnet;
