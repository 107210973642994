import React from 'react'
import { useGetAllTimeSlotQuery } from '../../../redux/api/timeSlotApi'

const Availibility = ({ doctorId }) => {
    const { data, isLoading, isError } = useGetAllTimeSlotQuery();
    console.log('data', data);

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Filter the data for the specific doctorId
    const doctorData = data?.filter(d => d.doctorId === doctorId);

    return (
        <div>
            <div className="widget business-widget">
                <div className="widget-content">
                    <div className="listing-hours">
                        {daysOfWeek.map(day => {
                            const dayData = doctorData?.find(d => d.day.toLowerCase() === day.toLowerCase());

                            return (
                                <div className={`listing-day ${dayData ? '' : 'closed'}`} key={day}>
                                    <div className="day"><strong>{day}</strong></div>
                                    <div className="time-items">
                                        {dayData ? (
                                            dayData.timeSlot.map(slot => (
                                                <span className="time" key={slot.id}>
                                                    {slot.startTime} - {slot.endTime} <br></br>
                                                </span>
                                            ))
                                        ) : (
                                            <span className="badge bg-danger-light" style={{border: '1px solid red', color: 'red'}}>Closed</span>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Availibility