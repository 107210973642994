import { useEffect, useState } from 'react';
import './index.css';
import useAuthCheck from '../../../redux/hooks/useAuthCheck';
import TopHeader from '../TopHeader/TopHeader';
import { Link, useNavigate } from 'react-router-dom';
import img from '../../../images/logo1.png';
import avatar from '../../../images/avatar.jpg';
import { Button, message } from 'antd';
import { loggedOut } from '../../../service/auth.service';
import HeaderNav from './HeaderNav';
import { getUserInfo } from '../../../service/auth.service';
import {userRole as userRoleConstant} from '../../../constant/role';

import { decodeToken} from '../../../utils/jwt.js'
import {getFromLocalStorage } from '../../../utils/local-storage.js'

const Header = () => {
    const navigate = useNavigate();
    const { authChecked, data } = useAuthCheck();
    const [isLoggedIn, setIsLogged] = useState(false);
    const [show, setShow] = useState(true);
    const [open, setOpen] = useState(false);
    const userData = getUserInfo();

    const [userRole, setUserRole] = useState(null);

    // Extract the user's role

    useEffect(() => {
        const accessTokenVal = getFromLocalStorage("accessToken");
        if (accessTokenVal) {
            try {
                const decodedToken = decodeToken(accessTokenVal);
                setUserRole(decodedToken.role);
            } catch (error) {
                console.error("Invalid token specified", error);
                setUserRole(null);
            }
        }
    }, []);


    // const lastScrollRef = useRef(0);
    const handleScroll = () => {
        const currentScroll = window.scrollY;
        // if (currentScroll > lastScrollRef.current) { // Undo scroll up effect
        if (currentScroll > 50) {
            setShow(false);
        } else {
            setShow(true);
        }
        // lastScrollRef.current = currentScroll;
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return (() => window.removeEventListener('scroll', handleScroll));
    }, [])

    useEffect(() => { authChecked && setIsLogged(true) }, [authChecked]);

    const hanldeSignOut = () => {
        loggedOut();
        message.success("Successfully Logged Out")
        setIsLogged(false)
        navigate('/')
    }


     // handling on click
     const handleAppointmentClick = (event) => {
        if (!isLoggedIn) {
            event.preventDefault();
            navigate('/login');
        } else {
            navigate('/doctors')
            // navigate('/appointment');  
        }
    }


    const content = (
        <div className='nav-popover'>
            <div className='my-2'>
                <h5 className='text-capitalize'>{data?.firstName + ' ' + data?.lastName}</h5>
                <p className='my-0'>{data?.email}</p>
                {userData?.role !== userRoleConstant.doctor && <Link to="/dashboard">Dashboard</Link>}
            </div>
            <Button variant="outline-danger" className='w-100' size="sm" onClick={hanldeSignOut}>
                Log Out
            </Button>
        </div>
    );
    return (
        <>
            <div className={`navbar navbar-expand-lg navbar-light ${!show && "hideTopHeader"}`} expand="lg">
                <TopHeader />
            </div>
            <header id="header" className={`fixed-top ${!show && "stickyHeader"}`}>
                <div className="container d-flex align-items-center">
                   
                    <Link to={'/'} className="logo me-auto">
                        <img src={img} alt="" className="img-fluid" />
                    </Link>
                    <HeaderNav isLoggedIn={isLoggedIn} data={data}
                        avatar={avatar} content={content} open={open} setOpen={setOpen} />
                     {(isLoggedIn && userRole === userRoleConstant.patient) || !isLoggedIn ? (
                        <Link 
                            to="/doctors"
                            className="appointment-btn scrollto"
                            onClick={handleAppointmentClick}
                        >
                            <span className="d-none d-md-inline">Make an</span> Appointment
                        </Link>
                    ) : null}

                    {/* ADDED NEW DASHBOARD BUTTON FOR SMALLER SCREEN*/}
                    {(isLoggedIn && userRole === userRoleConstant.doctor) ? (
                        <Link 
                            to="/dashboard"
                            className="appointment-btn scrollto dashboardButton"
                        >
                            <span className="d-md-inline">Dashboard</span> 
                        </Link>
                    ) : null}    
                    {/* <Link to={'/appointment'} className="appointment-btn scrollto"><span className="d-none d-md-inline">Make an</span> Appointment</Link> */}
                </div>
            </header>
        </>
    )
}

export default Header