import { useState, useEffect } from 'react';
import moment from 'moment'
import { DatePicker } from 'antd';
import { dateFormat } from '../../constant/global';
import { useGetDoctorClinicsQuery } from "../../redux/api/clinicApi";
import ColumnGroup from 'antd/es/table/ColumnGroup';

const SelectDateAndTime = ({ content, handleDateChange, handleClinicChange, disabledDateTime, selectedDate, dContent, selectTime, doctorId }) => {

    const [selectClinic, setSelectClinic] = useState({});
    const { data: clinics, isSuccess } = useGetDoctorClinicsQuery(doctorId);

    useEffect(() => {
        if (isSuccess && clinics.length > 0) {
            setSelectClinic(clinics[0]);
            handleClinicChange(clinics[0]?.id);
        }
    }, [clinics]);

    const handleSelectChange = (e) => {
        setSelectClinic(() => {
            return clinics.find((clinic) => clinic.id === e.target.value);
        });
        handleClinicChange(e.target?.value);
    }
    return (
        <div style={{ marginTop: '5rem' }}>
            <div>
                <h5 className='text-title'>Selected Doctor</h5>
                {content}
            </div>

            <dir className="row">

                <div className='col'>
                    <div>
                        <h5 className='text-title mb-3'>Please Select Clinic</h5>
                        <div className="col-md-10">
                            <div className="form-group mb-2 card-label">
                                <select value={selectClinic?.name} className="form-control select" onChange={handleSelectChange} name='clinic'>
                                    {clinics?.length > 0 && clinics.map((clinic) => {
                                        return <option key={clinic?.id} className='text-capitalize' value={clinic?.id} data-extra={clinic?.clinicName}>{clinic?.clinicName}, {clinic?.addressLine1}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5 col-sm-12 mt-3">
                        <h5 className='text-title mb-3'>Please Select Date</h5>
                        <DatePicker
                            format={dateFormat}
                            disabledDate={disabledDateTime}
                            onChange={handleDateChange}
                        />
                    </div>
                </div>

                <div className="col-md-7 col-sm-12 mt-3">
                    {selectedDate && <h5 className='text-title mb-3'>{selectClinic?.clinicName}, {selectClinic?.addressLine1}: {selectedDate && moment(selectedDate).format('LL')}
                        <div> </div> {selectTime && selectTime}</h5>}
                    <div className="date-card rounded">
                        <div className="row text-center mt-3">
                            {
                                !selectedDate ? <h5 className='text-title d-flex justify-content-center align-items-center mt-5'>Please Select A Date First</h5> :
                                    dContent
                            }
                        </div>
                    </div>
                </div>
            </dir>
        </div>
    )
}

export default SelectDateAndTime;