import { useState } from 'react'
import { Button, message } from 'antd'
import { useForm } from "react-hook-form"
import { useNavigate } from 'react-router-dom';
import { useCreateDoctorClinicMutation } from '../../../redux/api/clinicApi';

export default function ClinicForm() {
    const { register, handleSubmit } = useForm();
    const [createDoctorClinic, { isLoading, isError, isSuccess }] = useCreateDoctorClinicMutation();
    const [selectValue, setSelectValue] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        setSelectValue({ ...selectValue, [e.target.name]: e.target.value })
    }
    const onSubmit = async (data) => {
        const obj = { ...data, ...selectValue };
        try {
            await createDoctorClinic(obj).unwrap();
            message.success("Successfully added clinic.");
            navigate("/dashboard/clinics");
        } catch (error) {
            message.error("Please try again.");
        }
    }
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="col-md-12">
                    <div className="card mb-2 p-3 mt-2">
                        <h6 className="card-title text-secondary">Clinic Info</h6>
                        <div className="row form-row">
                            <div className="col-md-6">
                                <div className="form-group mb-2 card-label">
                                    <label>Clinic Name<span className="text-danger">*</span></label>
                                    <input className="form-control" {...register("clinicName", { required: true })} rows={5} />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group mb-2 card-label">
                                    <label>Address Line 1<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...register("addressLine1", { required: true })} />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group mb-2 card-label">
                                    <label>Address Line 2</label>
                                    <input type="text" className="form-control" {...register("addressLine2")} />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group mb-2 card-label">
                                    <label>City<span className="text-danger">*</span></label>
                                    <input className="form-control" {...register("city", { required: true })} />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group mb-2 card-label">
                                    <label>State / Province<span className="text-danger">*</span></label>
                                    <input className="form-control" {...register("state", { required: true })} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-2 card-label">
                                    <label>Country<span className="text-danger">*</span></label>
                                    <input className="form-control" defaultValue={"India"} {...register("country", { required: true })} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-2 card-label">
                                    <label>Postal Code<span className="text-danger">*</span></label>
                                    <input className="form-control" {...register("pinCode", { required: true })} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-2 card-label">
                                    <label>Clinic Status<span className="text-danger">*</span></label>
                                    <select value={selectValue?.clinicStatus} required={true} className="form-control select" onChange={handleChange} name='clinicStatus'>
                                        <option value={''}>Select</option>
                                        <option className='text-capitalize'>Open</option>
                                        <option className='text-capitalize'>Close</option>
                                        <option className='text-capitalize'>Pending</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center my-3">
                    <Button size="large" type="primary" htmlType="submit" disabled={isLoading}>Submit</Button>
                </div>
            </form>
        </div>
    )
}
