import { Checkbox, message } from 'antd';
import { useEffect, useState } from 'react';
import useAuthCheck from '../../redux/hooks/useAuthCheck';
import { validate } from '../../utils/personalInformation';


const PersonalInformation = ({ handleChange, selectValue, setPatientId, onData = () => { } }) => {

    const { firstName, lastName, email, phone, reasonForVisit, description, address,sex, age, weight  } = selectValue;
    const [checked, setChecked] = useState(false);
    const [errors, setErrors] = useState({});
    const { data } = useAuthCheck();    

    useEffect(() => {
        if (checked) {
            if (data.id) {
                setPatientId(data.id);
                // message.success("User Has Found !")
            } else {
                // message.error("User is not Found, Please Login!")
            }
        }
    }, [checked, data, setPatientId])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate(firstName, lastName, email, phone, reasonForVisit, description, address, sex, age, weight, setErrors)) {
            // Proceed with form submission            
            onData(true);
            console.log('Form values:', selectValue);
        } else {
            onData(false);
            console.log('Validation errors:', errors);
            }
    };

    return (
        <form className="rounded p-3 mt-5" style={{ background: "#f8f9fa" }} onSubmit={handleSubmit}>
            <div className="row">
                {/* <Checkbox checked={checked} onChange={onChange}>
                    Allready Have an Account ?
                </Checkbox> */}

                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>First Name</label>
                        <input onChange={(e) => handleChange(e)} onClick={() => (setChecked(true))} name='firstName' value={firstName && firstName} className="form-control" type="text" />
                        {errors.firstName && <span className="text-danger">{errors.firstName}</span>}
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Last Name</label>
                        <input onChange={(e) => handleChange(e)} name='lastName' value={lastName && lastName} className="form-control" type="text" />
                        {errors.lastName && <span className="text-danger">{errors.lastName}</span>}
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Email</label>
                        <input onChange={(e) => handleChange(e)} name='email' value={email && email} className="form-control" type="email" />
                        {errors.email && <span className="text-danger">{errors.email}</span>}
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Phone</label>
                        <input onChange={(e) => handleChange(e)} name='phone' value={phone && phone} className="form-control" type="text" />
                        {errors.phone && <span className="text-danger">{errors.phone}</span>}
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Reason For Visit</label>
                        <textarea rows={8} onChange={(e) => handleChange(e)} name='reasonForVisit' value={reasonForVisit && reasonForVisit} className="form-control" type="text" />
                        {errors.reasonForVisit && <span className="text-danger">{errors.reasonForVisit}</span>}
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Description</label>
                        <textarea rows={8} onChange={(e) => handleChange(e)} name='description' value={description && description} className="form-control" type="text" />
                        {errors.description && <span className="text-danger">{errors.description}</span>}
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Address</label>
                        <input onChange={(e) => handleChange(e)} name='address' value={address && address} className="form-control" type="text" />
                        {errors.address && <span className="text-danger">{errors.address}</span>}
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Sex</label>
                        <input onChange={(e) => handleChange(e)} name='sex' value={sex || ''} className="form-control" type="text" />
                        {errors.sex && <span className="text-danger">{errors.sex}</span>}
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Age</label>
                        <input onChange={(e) => handleChange(e)} name='age' value={age || ''} className="form-control" type="number" />
                        {errors.age && <span className="text-danger">{errors.age}</span>}
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group card-label mb-3">
                        <label>Weight</label>
                        <input onChange={(e) => handleChange(e)} name='weight' value={weight || ''} className="form-control" type="number" />
                        {errors.weight && <span className="text-danger">{errors.weight}</span>}
                    </div>
                </div>

            </div>
            <button type="submit" className="btn btn-primary">Validate</button>
            
        </form>
    )
}

export default PersonalInformation;