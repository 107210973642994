import React, { useEffect, useState } from 'react'
import img from '../../../images/chair.png'
import { useGetDoctorClinicsQuery } from '../../../redux/api/clinicApi';
const Location = ({ doctorId }) => {

    const { data, isLoading, isError, refetch } = useGetDoctorClinicsQuery(doctorId);
    return (
        <div className="location-list ">
            {
                data?.map((clinic, index) => (
                    <div className='card shadow p-1 border-0 mb-3'>
                        <div className="row">

                            <div className="col-md-6">
                                <div className=" clinic-content">
                                    <h4 className="clinic-name"><a href="#"> {`${clinic.clinicName} Clinic`}</a></h4>
                                    {/* <p className="doc-speciality">MDS - Periodontology and Oral Implantology, BDS</p> */}
                                    <div className="clinic-details mb-0">
                                        <h5 className="clinic-direction"> <i className="fas fa-map-marker-alt"></i>{`${clinic.addressLine1}, ${clinic.city}, ${clinic.state}, ${clinic.pinCode}, ${clinic.country}`} <br /><a>Get Directions</a></h5>
                                        <ul>
                                            {
                                                Array(4).fill(null).map((_item, index) => (
                                                    <li key={index + 2}>
                                                        <a>
                                                            <img src={img} alt="Feature Image" />
                                                        </a>
                                                    </li>
                                                ))
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="clinic-timing">
                                    <div >

                                        <ul>
                                            {clinic.doctorTimeSlot.map((item, key) => {
                                                return <div>
                                                    <li className='mt-3'>

                                                        <h6 className='text-success'>{`${item.day.toUpperCase()} : `} </h6>

                                                        <span className='d-flex  flex-column flex-xl-row flex-md-column flex-sm-column'>

                                                            {item.timeSlot.map((time, key) => {
                                                                return <span className='text-primary'> {` | ${time.startTime}  -  ${time.endTime}  | `} </span>
                                                            })}

                                                        </span>

                                                    </li>

                                                </div>
                                            })}


                                        </ul>



                                    </div>

                                </div>
                            </div>

                            {/* <div className="col-md-2">
                                <div className="consult-price">
                                    $250
                                </div>
                            </div> */}
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Location