import React from 'react'
import AdminLayout from '../AdminLayout/AdminLayout'
import './Profile.css';

const Profile = () => {
    return (
        <>
            <AdminLayout >
               
            </AdminLayout>
        </>
    )
}
export default Profile;