import React from 'react'
import AdminLayout from '../AdminLayout/AdminLayout'
import './Doctors.css';

const Doctors = () => {
    return (
        <>
            <AdminLayout >
               
            </AdminLayout>
        </>
    )
}
export default Doctors;