import React from 'react'
import SubHeader from '../Shared/SubHeader'
import Footer from '../Shared/Footer/Footer'
import Header from '../Shared/Header/Header'
// import img from '../../images/features/baby.png'
import img1 from '../../images/features/services/img1.jpeg';
import img2 from '../../images/features/services/img2.png';
import img3 from '../../images/features/services/img3.png';
import img4 from '../../images/features/services/img4.jpg';
import img5 from '../../images/features/services/img5.png';
import img6 from '../../images/features/services/img6.png';
import img7 from '../../images/features/services/img7.png';
import img8 from '../../images/features/services/img8.png';
import img9 from '../../images/features/services/img9.png';
import img10 from '../../images/features/services/img10.png';
import img11 from '../../images/features/services/img11.png';
import img12 from '../../images/features/services/img12.png';
import img13 from '../../images/features/services/img13.png';
// import img14 from '../../images/features/services/img14.png';
import img15 from '../../images/features/services/img15.png';
import { useState } from 'react';
import { Link } from 'react-router-dom'
import doctorBg from '../../images/img/doctors-bg.jpg';

const cardData = [
  { id: 1, title: 'Clinical Dermatology', description: 'Specialized in diagnosing and treating skin conditions.', img: img1 },
  { id: 2, title: 'Pediatric Dermatology', description: 'Focused on skin care for infants, children, and adolescents.', img: img2 },
  { id: 3, title: 'Geriatric Dermatology', description: 'Provides dermatological care for elderly patients.', img: img3 },
  { id: 4, title: 'STD Clinic/Sexual Disorders/Sexologist', description: 'Specializes in sexually transmitted diseases and sexual disorders.', img: img4 },
  { id: 5, title: 'Leprosy', description: 'Offers treatments for leprosy and related conditions.', img: img5 },
  { id: 6, title: 'Dermatosurgery', description: 'Expertise in surgical procedures for skin conditions.', img: img6 },
  { id: 7, title: 'Laser Treatment', description: 'Provides laser treatments for various dermatological issues.', img: img7 },  
  { id: 8, title: 'Hair Treatment', description: 'Specializes in treatments for hair and scalp conditions.', img: img8 },
  { id: 9, title: 'Hair Transplant', description: 'Offers hair transplant procedures for hair loss.', img: img9 },
  { id: 10, title: 'PRP Therapy', description: 'Provides platelet-rich plasma therapy for skin rejuvenation.', img: img10 },
  { id: 11, title: 'Permanent Makeup (Eyebrow, Dark lip correction)', description: 'Provides platelet-rich plasma therapy for skin rejuvenation.', img: img11 },
  { id: 12, title: 'Cryosurgery', description: 'Provides specialized surgical treatments.', img: img12 },
  { id: 13, title: 'Botox', description: 'Offers Botox treatments for cosmetic enhancements.', img: img13 },
  // { id: 14, title: '<strong>to confirm</strong>', description: 'Description for confirmation.', img: '' },
  { id: 15, title: 'Thread lift', description: 'Provides thread lift procedures for skin tightening.', img: img15 },
];


const Service = () => {
  const weArePleaseStyle = {
    backgroundColor: "antiquewhite",
    height: "60vh",
    background: `url(${doctorBg}) no-repeat`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    padding: "10px",
    position: "relative",
    marginTop: 200,
    marginBottom: 100
  }

  const [isClicked, setIsClicked] = useState(false);

  const handleOnClick = () => {
    setIsClicked(!isClicked);
  }

  return (
    <>
      <Header />
      <SubHeader title="Service" subtitle="Take a look at the services we provide." />

      <div className="container" style={{ marginTop: 200, marginBottom: 100 }}>
        <div className="row">
          {
            // Array(6).fill(null).map((_item, id) => (
              cardData.map((item) => (
              <div className="col-lg-4 col-md-6 col-sm-6" /*key={id + 6}*/ >
                <div className="card shadow border-0 mb-5">
                  <img src={item.img} alt={item.title} className="img-fluid" style={{ maxHeight: '17rem', minHeight: '17rem', objectFit: 'cover' }} />
                  <div className="p-2" style={{minHeight: '12rem'}}>
                    <h4 className="mt-4 mb-2"
                      style={isClicked ? {cursor: 'pointer'} : {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                      }}
                      onClick={handleOnClick}
                    >{item.title}</h4>
                    <p className="mb-4"
                      style={isClicked ? {cursor: 'pointer'} : {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                      }}
                      onClick={handleOnClick}
                    >{item.description}</p>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>

      <section style={weArePleaseStyle}>
        <div className="container" style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
          <div className="row">
            <div className="col-lg-7">
              <div className="d-flex align-items-center">
                <div className='mb-4 section-title text-center'>
                  <h2 className='text-uppercase'>We are pleased to offer you</h2>
                  <p className='form-text' style={{textAlign: 'justify'}}>The exceptional services, we have highly skilled dermatologist known for providing the best treatments and outstanding care. We are specialized in advanced dermatological procedures and are dedicated to ensuring that each patient receives personalized and comprehensive care. With a reputation for excellence and a commitment to the latest medical advancements, we are consistently delivering outstanding results and patient satisfaction. <br />
                  Experience the highest quality of dermatological care, where your skin health and well-being are our top priorities.</p>
                  <Link to={'/doctors'} className="btn-get-started scrollto">Get Started</Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>


      <Footer />
    </>
  )
}

export default Service