import React from 'react'
import AdminLayout from '../AdminLayout/AdminLayout'
import './Patients.css';

const Patients = () => {
    return (
        <>
            <AdminLayout >
               
            </AdminLayout>
        </>
    )
}
export default Patients;