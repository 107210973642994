import './index.css';
import { FaHospitalUser, FaCalendarAlt, FaHospital } from "react-icons/fa";
import { useEffect, useState } from 'react';
import { useGetDoctorAppointmentsQuery } from '../../../../redux/api/appointmentApi';
import moment from 'moment';
const DoctorDashCard = () => {
    const [todayAppointments, setTodayAppointments] = useState(0);
    const [upcomingAppointments, setUpcomingAppointments] = useState(0);
    
    const sortBy = 'all';
    const  allData  = useGetDoctorAppointmentsQuery({sortBy});

    const appointmentsData =  allData?.data || [];
    
    useEffect(() => {
        if (appointmentsData) {
            const today = moment().format("YYYY-MM-DD");
            const todayCount = appointmentsData.filter(app => moment(app.scheduleDate).format("YYYY-MM-DD") === today).length;
            const upcomingCount = appointmentsData.filter(app => moment(app.scheduleDate).isAfter(today)).length;
            
            setTodayAppointments(todayCount);
            setUpcomingAppointments(upcomingCount);
        }
    }, [appointmentsData]);
    const cardData = [
        {
            icon: <FaHospitalUser className='icon active' />,
            title: 'Today Appointments ',
            amount: todayAppointments,
            date: moment().format("LL")
        },
        {
            icon: <FaCalendarAlt className='icon danger' />,
            title: 'Upcoming Appointments',
            amount: upcomingAppointments,

        }
    ]
    return (

        <div className="row mb-4 p-3 rounded" style={{ background: '#f8f9fa' }}>
            {
                cardData.map((item, index) => (
                    <div className="col-md-12 col-lg-4" key={index + 8}>
                        <div className='d-flex gap-2 align-items-center dash-card'>
                            <div className='dash-card-icon'>
                                {item.icon}
                            </div>
                            <div className="dash-widget-info">
                                <h6 className='mb-0'>{item.title}</h6>
                                <h4 className='my-1'>{item.amount}</h4>
                                <p className="form-text">{item.date}</p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>

    )
}
export default DoctorDashCard;