import React from 'react';
import img1 from '../../../images/specialities/skin.jpg';
import img2 from '../../../images/specialities/hair.png';
import img3 from '../../../images/specialities/plastic.png';
import img4 from '../../../images/specialities/face Lifting line.jpg';
//import img5 from '../../../images/specialities/specialities-05.png';
import { FaCheckDouble } from "react-icons/fa";
import './index.css';

const ClinicAndSpecialities = () => {
	return (
		<section className="section section-specialities position-relative">
			<div className="container-fluid">
				<div className='mb-5 section-title text-center'>
					<h2>Clinic and Specialities</h2>
					<p className='m-0'>{/* PUT CONTENT HERE */}</p>
				</div>

				<div className="w-100 row justify-content-center">
					<div className="col-md-9">
						<div className="w-100 specialities-slider d-flex justify-content-left align-items-center gap-5">
							<div className="col speicality-item text-center">
								<div className="speicality-img">
									<img src={img1} className="img-fluid" alt="" />
									<span><i><FaCheckDouble/></i></span>
								</div>
								<p>Skin</p>
							</div>
							<div className="col speicality-item text-center">
								<div className="speicality-img">
									<img src={img2} className="img-fluid" alt="" />
									<span><i><FaCheckDouble/></i></span>
								</div>
								<p>Hair</p>
							</div>
							<div className="col speicality-item text-center">
								<div className="speicality-img">
									<img src={img3} className="img-fluid" alt="" />
									<span><i><FaCheckDouble/></i></span>
								</div>
								<p>Plastic Surgery</p>
							</div>
							<div className="col speicality-item text-center">
								<div className="speicality-img">
									<img src={img4} className="img-fluid" alt="" />
									<span><i><FaCheckDouble/></i></span>
								</div>
								<p>Face Line Lifting </p>
							</div>
							{/* <div className="speicality-item text-center">
								<div className="speicality-img">
									<img src={img5} className="img-fluid" alt="" />
									<span><i><FaCheckDouble/></i></span>
								</div>
								<p>Dentist</p>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ClinicAndSpecialities;