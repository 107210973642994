import { useState } from 'react'
import { Button, Modal, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { useGetDoctorClinicsQuery, useDeleteDoctorClinicMutation } from '../../../redux/api/clinicApi';
import { getUserInfo } from '../../../service/auth.service';
import "./ClinicPage.css"

export default function ClinicPage() {
    const userPayload = getUserInfo();
    const { data, isLoading, isError, refetch } = useGetDoctorClinicsQuery(userPayload?.userId);
    const [deleteDoctorClinic, { isError: deleteError, isSuccess: deleteSuccess }] = useDeleteDoctorClinicMutation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const navigate = useNavigate();

    const handleDeleteClick = (item) => {
        setIsModalOpen(true);
        setItemToDelete(item);
    }
    const handleDeleteCancel = () => {
        setIsModalOpen(false);
        setItemToDelete(null);
    }
    const handleDeleteConfirm = async () => {
        try {
            await deleteDoctorClinic(itemToDelete?.id).unwrap();
            message.success("Successfully deleted clinic.");
            setIsModalOpen(false);
            refetch();
        } catch (error) {
            message.error("Please try again.");
            setIsModalOpen(false);
        }
    }
    return (
        <>

            <div className="clinic-list-container">
                {data && data?.map((clinic) => {

                    return (<div key={clinic.id} className='clinic-list-item'>
                        <div>
                            <span> {clinic?.clinicName} Clinic </span> {clinic?.addressLine1} ,  {clinic?.city} , {clinic?.state}
                        </div>
                        <div className='clinic-list-icons'><FaEdit size={22} className='clinic-edit' onClick={() => navigate(`/dashboard/clinic/edit/${clinic.id}`)} /> <MdDelete size={22} className='clinic-delete' onClick={() => handleDeleteClick(clinic)} /></div>
                    </div>)
                })}
            </div>
            <Button size="large" type='primary' onClick={() => navigate("/dashboard/clinic/add")}>Add Clinic</Button>
            <Modal
                title="Are you sure to delete this clinic?"
                open={isModalOpen}
                onCancel={handleDeleteCancel}
                onOk={handleDeleteConfirm}
            />
        </>
    )
}
