import DoctorDashCard from '../Dashboard/doctor/DoctorDashCard';
import useAuthCheck from '../../../redux/hooks/useAuthCheck';
import DashboardLayout from '../DashboardLayout/DashboardLayout';
import ClinicPage from './ClinicPage';


export default function Clinics() {
    const { role } = useAuthCheck();

    return (
        <>
            <DashboardLayout>
                {role === 'doctor' && <DoctorDashCard />}

                <div className="row">

                    {role === 'doctor' &&
                        <div className="col-md-12 rounded" style={{ background: '#f8f9fa' }}>
                            <h5 className="text-title py-3">Clinics</h5>
                            <ClinicPage />
                        </div>
                    }

                </div>
            </DashboardLayout>
        </>
    )
}
