import React from 'react'
import AdminLayout from '../AdminLayout/AdminLayout'
import './Reviews.css';

const AdminReviews = () => {
    return (
        <>
            <AdminLayout >
               
            </AdminLayout>
        </>
    )
}
export default AdminReviews;