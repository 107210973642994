import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDeleteCommentMutation } from "../../redux/api/commentApi";
import useAuthCheck from "../../redux/hooks/useAuthCheck";
import AddCommnet from "./AddCommnet";
import { useGetSingleCommentQuery } from "../../redux/api/commentApi";

import "./index.css"
import Replies from "./Replies";
function Comment({ data,user:Blog}) {

  const [deleteComment] = useDeleteCommentMutation();
 
  const { data: userData } = useAuthCheck();
  const [isReply, setisReply] = useState(false);
  const [replies, setreplies] = useState(false);
  const [showReplys, setshowReplys] = useState(false);
  const { data: singleComment } = useGetSingleCommentQuery(
    data?.id
  );
  async function clickhandler() {
    await deleteComment(data.id);
  }
  const user=data?.authorId?data?.author:data?.pateint;
 
 
  function Reply() {
    setisReply(true);
  }


  useEffect(()=>{
    

  },[replies===true])
 
  if (singleComment) {
    var children=singleComment[0]?.children;
    
   
  }



  return (
    <div>
      <div>
        <div className="d-flex gap-3 mb-3 mt-5 w-75 ">
          <div>
            <img
              src={
                user?.img ||
                "https://img.freepik.com/free-vector/man-red-shirt-with-white-collar_90220-2873.jpg?w=826&t=st=1722017224~exp=1722017824~hmac=8db4285629a69fed11d62d36051f8de2ae21bc0dfe2d6a33d49547e5b3fa2d2f"
              }
              width={30}
              className=" rounded-circle"
              alt="User"
            />
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between ">
              <p className="htext me-5"  >{user?.firstName + " " + user?.lastName}</p>
              <p className=" mb-0 htext ms-lg-5">
               { `${moment(data?.createdAt).format("h:mm A")}  
                ${moment(data?.createdAt).format("MMMM D, YYYY")}`}
              </p>
            </div>
            
          
              <p className="ptext width ">{data.message}</p>
           
            <div>
              <div className="d-flex justify-content-between flex-column w-100 " >
                <span className="position-relative">
                  {
                    data?.children.length !== 0&&<button className="button-B txtC"
                    onClick={() => {
                      setshowReplys(!showReplys);
                     
                   }}
                     >{data?.children.length} Replies
   
                     </button>
                  }
                  <button className="button-B"
                  onClick={() => {
                     Reply()
                      
                  }}
                  >Reply

                  </button>
                 { (userData?.id===(data?.authorId)||userData?.id===(data?.pateintId))&&
                  <button className="button-B"
                 onClick={() => {
                  clickhandler();
                  window.location.reload(false);
                }}
                  >Delete

                  </button>}
                 

                </span>
                <div >
                {isReply && (
                    <AddCommnet
                      setisReply={setisReply}
                      isReply={isReply}
                      Blog={Blog}
                      id={data?.id}
                    />
                  )}

                </div>
                <div>
                  {showReplys&&
                    children?.map((data)=>(
                      <Replies data={data}/>
                    ))
                  }
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Comment;
