import React from 'react'
import AdminLayout from '../AdminLayout/AdminLayout'

import './Transactions.css';

const Transactions = () => {
    return (
        <>
            <AdminLayout >
               
            </AdminLayout>
        </>
    )
}
export default Transactions;